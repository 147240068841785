import React from 'react';
import styled from 'styled-components';
import { Phone } from 'lucide-react';

const HeaderContainer = styled.header`
  background-color: #ff6b6b;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
`;

const UrgentMessage = styled.p`
  margin: 0;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const ContactNumber = styled.a`
  color: white;
  font-weight: 600;
  text-decoration: none;
  margin-left: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const UrgentServiceHeader = () => {
  return (
    <HeaderContainer>
      <UrgentMessage>
        <Phone size={16} />
        For urgent service, contact us on:
        <ContactNumber href="https://wa.me/9771234567890" target="_blank" rel="noopener noreferrer">
          WhatsApp: +977 9841444134
        </ContactNumber>
        |
        <ContactNumber href="viber://chat?number=%2B9771234567890" target="_blank" rel="noopener noreferrer">
          Viber: +977 9841444134
        </ContactNumber>
      </UrgentMessage>
    </HeaderContainer>
  );
};

export default UrgentServiceHeader;